import { useState } from 'react';

import './App.scss';

import tv from './assets/tv.png';

import BaseCodeWidget from './components/BaseCodeWidget';
import Player from './components/Player';

function App() {
	const [baseCode, setBaseCode] = useState('');
	const [folderUUID, setFolderUUID] = useState(null);

	return (
		<div className='App'>
			{folderUUID ? (
				<Player folderUUID={folderUUID} />
			) : (
				<>
					<header className='App-header'>
						<img src={tv} alt='logo' className='tv-logo' />
						<div className='logo-text'>
							<h1>5 Clicks Up</h1>
							<p>Sync your stories in 5 clicks.</p>
						</div>
					</header>

					<BaseCodeWidget
						baseCode={baseCode}
						setBaseCode={setBaseCode}
						setFolderUUID={setFolderUUID}
					/>
				</>
			)}
		</div>
	);
}

export default App;
