import React, { useEffect, useState } from 'react';
import axios from 'axios';

//3rd party
import { Splide, SplideSlide } from '@splidejs/react-splide';

//CSS
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { getMediaBaseApiUrl } from '../utils';

const Player = ({ folderUUID }) => {
	const [shots, setShots] = useState([]);
	const [isGettingShots, setIsGettingShots] = useState(false);
	const [isLoadingShot, setIsLoadingShot] = useState(false);

	// Ping API to get our list of shots.
	useEffect(() => {
		console.log('Player: Getting list of shots.');
		const url = getMediaBaseApiUrl(`/dev/media_bases/shot_list?f=${folderUUID}`);
		axios.get(url).then((res) => {
			const shots = res.data?.Contents;
			if (shots?.length > 0) {
				console.log('Player: Found ' + shots.length + ' shots.');
				setShots(shots);
			}
		});
	}, [folderUUID]);

	return (
		<div className='player'>
			{!shots.length && <h1>No shots found.</h1>}
			{shots.length > 0 && (
				<Splide
					options={{
						type: 'loop',
						width: '100vw',
						height: '100vh',
						hasAutoplayProgress: true,
						hasAutoplayControls: true,
						easing: 'ease',
						lazyLoad: 'nearby',
						autoplay: true,
						interval: 5000,
						pauseOnHover: false,
					}}
				>
					{shots.map((shot) => {
						// we need to encode the game and shot name.
						const hostname = 'https://digital-storytime.s3-us-west-2.amazonaws.com';
						const parts = shot.Key.split('/');
						const folderUUID = parts[0];
						const gameName = encodeURIComponent(parts[1]);
						const shotName = encodeURIComponent(parts[2]);
						const src = `${hostname}/${folderUUID}/${gameName}/${shotName}`;
						return (
							<SplideSlide key={src}>
								<img data-splide-lazy={src} alt={`${gameName} Shot`} />
							</SplideSlide>
						);
					})}
				</Splide>
			)}
		</div>
	);
};

export default Player;
