import { useState } from 'react';
import axios from 'axios';
import { getMediaBaseApiUrl } from '../utils';

const BaseCodeWidget = ({ baseCode, setBaseCode, setFolderUUID }) => {
	const [hasBaseCodeError, setHasBaseCodeError] = useState(false);

	const handleFieldChange = (evt) => {
		setBaseCode(evt.target.value.toUpperCase());
	};

	const handleSubmit = (evt) => {
		evt.preventDefault();

		// Find our media base so we can start showing shots
		const url = getMediaBaseApiUrl(`/dev/media_bases/base_code?bc=${baseCode}`);
		axios.get(url).then((res) => {
			const results = res.data;
			if (!results.length) {
				setHasBaseCodeError(true);
			} else {
				// We have our folder UUID.
				setHasBaseCodeError(false);
				setFolderUUID(res.data[0].folder_uuid);
			}
		});
	};

	return (
		<div className='base-code-widget'>
			{hasBaseCodeError && (
				<div className='alert alert-danger' role='alert'>
					We couldn't find your Media Base with that code!
				</div>
			)}

			<p>Enter your Base Code:</p>
			<form onSubmit={handleSubmit}>
				<div className='mb-3'>
					<input
						className='form-control form-control-lg'
						type='text'
						placeholder='ABC123'
						aria-label='base code example'
						onChange={handleFieldChange}
						value={baseCode}
						maxLength={6}
					/>
				</div>

				<div className='mb-3'>
					<button className='btn btn-success btn-lg'>Submit</button>
				</div>
			</form>
		</div>
	);
};

export default BaseCodeWidget;
